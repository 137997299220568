

// Has to be 'exports.default = ' instead of 'export default' for interop with both node and es6
const def = function(ctx) { /* global exports */
	const t = ctx.types;

	return {
		// Table is NOT USED in client - it's only used by the sequalize conversion and
		// the server/bin/schema-sync script
		table: 'payment_methods',
		
		// If this is set to true, this schema will NOT be synched DB, 
		// since this schema is actually managed by Nutralysis and AppCore, 
		// NOT by the node server code. This defaults to false for new models,
		// you must edit this file and manually set this to true after generating the model.
		legacyExternalSchema: false,

		// 'id' is auto-added by all consumers of model definitions
		schema: {
			// If legacyExternalSchema is set to true, then this schema is managed by Nutralysis perl code,
			// it will uses a different id column than 'id', so you must explicitly tell 
			// Sequelize/client/Feathers the name of the column. So uncomment this line below and edit accordingly.
			// userid:    t.id,
			
			person: t.linked('person'),
			
			typeId: t.string, // see def.types, below

			// Useful name based on type. Examples:
			// - Monthly Pass (May 2019)
			// - Annual Pass (2019)
			// - 10 Punch Card
			// - Card On File (Visa *3301)
			// Note: We don't support card-on-file yet - TODO: Stripe integration
			name:    t.string,
			// Useful etails example:
			// 6 of 10 reminaing
			// 1/1 - 2/1/19
			details: t.string,

			// Name of promotion for use with 'promopunch' type
			promoName:  t.text,

			// punch cards - not dated
			// annual/monthly passes - dated
			isDated:   t.boolean,
			startDate: t.datetime,
			endDate:   t.datetime,

			// Editable in UI
			// purchaseDate: t.datetime,
			purchasedToday: t.string,
			purchasePrice: t.real, // just like it says on the tin

			// Not editable in UI, only set if checkbox for purchaseDate=today clicked from checkin screen
			purchaseTx:   t.linked('checkin'),

			// Punch card data
			isPunchCard:      t.boolean,
			// Unnecessary
			// totalPunches:     t.int,
			remainingPunches: t.int,

			// Number of times this payment method used
			// Note: This payment method .id will be stored on the checkin as well
			usageCount: t.int,
		},

		sortBy:      ['createdAt','name'],  // can also be a hash like {name:-1} to invert sort order - not used yet by client
		stringifyAs: ['#name'], // stringifyAs defaults to first t.string anyway, fields must be prefixed with '#', otherwise content is assumed to be a literal string

		// Not used by client, created on the databse by schema-sync
		indexes: {
			name:   ['name'],
			person: ['person'],
		},

		// common fields like createdBy, updatedBy, account, etc
		// included from -common-fields in this folder
		// Note: the model factories may automatically add other fields,
		// such as deletedAt and migrationId to support low-level data
		// features as needed. This flag has no effect on those fields.
		includeCommonFields: true,
	}
};

// Define a list of expected/allowed types
def.types = {
	punch:      { name: "Punch Card" },
	promopunch: { name: "Promo" },
	cash:       { name: "Cash",        stored: false },
	cc:         { name: "Credit Card", stored: false },
	annual:     { name: "Annual Pass" },
	monthly:    { name: "Monthly Pass" },
	privates:   { name: "Private Lessons" },
	other:      { name: "Other",       stored: false },
};

// Put id in each def.type, above, for ease of use externally
// and so we don't have to type it twice above
for(let id in def.types) {
	def.types[id].id = id;
}

// Export
exports.default = def;