/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React from 'react';
import {
	Button, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,
	FormGroup,
	Row,
	InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import styles from './styles.module.scss';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
 
import moment from 'moment';

import properCase from '../../utils/proper-case';
import def from '../../../../core/db/defs/payment-method';

const PaymentTypes = def.default.types;


class Editor extends React.Component {
	constructor(props) {
		super(props);

		this.deleteAction = this.deleteAction.bind(this);
		this.closeAction = this.closeAction.bind(this);
		this.saveAction  = this.saveAction.bind(this);
		this.fieldChanged = this.fieldChanged.bind(this);

		this.state = {
			// changes: {}
			model: this.props.model || {}
		};

		// console.log("[modal] model=", this.state.model);
	}

	closeAction() {
		this.props.onCancel();
	}

	saveAction() {
		this.props.onSaved(this.state.model);
	}

	deleteAction() {
		if (window.confirm("Are you sure?"))
			this.props.onDelete();
	}

	fieldChanged(event) {
		let target = event.target,
			value    = target.value,
			name     = target.name;

		if(this.state.model) {
			if(name === 'promoName')
				value = properCase(value);

			this.state.model[name] = value;

			if(name == "startDate" || name == "typeId") {
				const startMoment = moment(this.state.model.startDate);
				let endMoment;

				if(this.state.model.typeId == 'annual') {
					endMoment = moment(startMoment).add(1, 'year');
				} else {
					endMoment = moment(startMoment).add(1, 'month');
				}

				this.state.model.endDate = endMoment.toDate();
			}
		}


		this.setState({ model: this.state.model });
	}

	render() {
		// console.log( { PaymentTypes });
		return (
			<Modal isOpen={true} toggle={this.closeAction} className={this.props.className} size="lg">
				<ModalHeader toggle={this.closeAction}>{this.props.createMode ? "Add Payment Method" : "Edit Payment Method"}</ModalHeader>
				<ModalBody>
					<FormGroup>
						<label>Type of Payment Method</label>
						{/* <Input defaultValue={this.model.typeId} name="typeId" onBlur={this.fieldChanged}/> */}
						<Input type="select" name="typeId" defaultValue={this.state.model.typeId} onChange={this.fieldChanged}>
							{Object.values(PaymentTypes).filter(type => type.stored !== false).map(type => 
								<option key={type.id} value={type.id}>{type.name}</option>
							)}
						</Input>
					</FormGroup>

					{["punch","privates","promopunch"].includes(this.state.model.typeId) && <>
						{this.state.model.typeId === 'promopunch' &&
							<FormGroup>
								<label>Promotion Name</label>
								<Input type="text"
									defaultValue={properCase(this.state.model.promoName)}
									name="promoName"
									onBlur={this.fieldChanged}/>
							</FormGroup>
						}

						{/* <FormGroup>
							<label>Number of Punches Available</label>
							<Input type="number" min="1" max="999" step="1"
								defaultValue={this.state.model.totalPunches}
								name="totalPunches"
								onBlur={this.fieldChanged}/>
						</FormGroup> */}
						<FormGroup>
							<label>Number of Punches</label>
							<Input type="number" min="1" max="999" step="1"
								defaultValue={this.state.model.remainingPunches}
								name="remainingPunches"
								onBlur={this.fieldChanged}/>
							<small className="form-text text-muted">
								This is the number of punches remaining on the card. The Check-In screen will automatically deduct punches from this number.
							</small>
						</FormGroup>
					</>}

					{["annual","monthly"].includes(this.state.model.typeId) && <>
						<FormGroup>
							<label>Start Date</label>
							<div>
								<DatePicker
									selected={moment(this.state.model.startDate).toDate()}
									onChange={value => this.fieldChanged({ target: { name: "startDate", value }})}
									className="form-control"
								/>
							</div>
							<span className="form-text">
								This pass will be valid until <b>{moment(this.state.model.endDate).format('M/D/YY')}</b>
							</span>

						</FormGroup>
					</>}


					{/* <Row> */}
						{/* <FormGroup className="col-md-6">
							<label>Purchase Date</label>
							<div>
								<DatePicker
									selected={moment(this.state.model.purchaseDate).toDate()}
									onChange={value => setTimeout(() => this.fieldChanged({ target: { name: "purchaseDate", value }}), 100) }
									className="form-control"
								/>
							</div>
							<small className="form-text text-muted">
								This is the date the card was purchased, if not set, defaults to today.
							</small>

						</FormGroup> */}

						{this.state.model.checkin && <FormGroup>
							<label>Purchased Today?</label>
							{/* <Input defaultValue={this.model.typeId} name="typeId" onBlur={this.fieldChanged}/> */}
							<Input type="select" name="purchasedToday" defaultValue={this.state.model.purchasedToday} onChange={this.fieldChanged}>
								<option value="yes">Yes, Purchased Today, Record Purchase Price</option>
								<option value="no">No, Purchased Previously</option>
							</Input>
						</FormGroup>}

						<FormGroup>
							<label>Purchase Price</label>
							<Input type="number" min="0" max="999999" step="any"
								defaultValue={this.state.model.purchasePrice}
								name="purchasePrice"
								onBlur={this.fieldChanged}/>
							<small className="form-text text-muted">
								This is the price they paid for the card, this will be used to calculate income on the day in question. If this is being entered on a check-in screen, this income will be added to the dollar value of the check-in.
							</small>
						</FormGroup>
					{/* </Row> */}
				
				</ModalBody>
				<ModalFooter className={styles.footer}>
					<div>
						{!this.props.createMode && 
							<Button color="danger" onClick={this.deleteAction}>Delete</Button>
						}
					</div>
					<ButtonGroup>
						<Button color="secondary" className='text-white' onClick={this.closeAction}>Cancel</Button>
						<Button color="success" className='text-white'  onClick={this.saveAction}>Save Changes</Button>{' '}
					</ButtonGroup>
				</ModalFooter>
			</Modal>
			
		);
	}
}

export default Editor;