import client from './feathers';
import navigate from './navigate';

export class FeathersList {
	list = [];
	onChange = null;
	service = null;
	serviceName = null;
	query = {};
    /**
     *Creates an instance of Classes to download the class list from server and notify client of changes
     * @param {String} serviceName - Name of service in feathers server to load
     * @param {Function} onChange - This callback will be called when class list is received from server and whenever server notfies of changes to the list
     * @param {Object} query - (Optional) Parameters to pass to feathers to load
     * @memberof Classes
     */
	constructor(serviceName, onChange, query = {}) {
		if (!serviceName)
			throw new Error("Must give serviceName to FeathersList constructor");
		// Call onChange with list whenever list changes
		this.onChange = onChange;
		// Client is our feathers client, get handle to the service
		this.serviceName = serviceName;
		this.service = client.service(serviceName);
		this.query = query; // sent to feathers
		// Get list from server and listen for changes
		this.on();
	}
    /**
     * on - Get list from server and start listening for changes
     *
     * @memberof Classes
     */
	on() {
		// Trigger download
		this.updateQuery();

		// Handle server "remove" events for when a record is removed on the server
		this.service.on('removed', this.removeHandler = removed => {
			// console.log('[removed handler]', { removed });
			if(!removed)
				return;

			this.list = this.list.filter(item => item.id !== removed.id);
			if (this.onChange)
				this.onChange(this.list);
		});
		// Handle server "created" events for when a record is createdon the server
		this.service.on('created', this.createdHandler = item => {
			this.list.push(item);
			if (this.onChange)
				this.onChange(this.list);
		});
		// Handle server "update" (patched) events when a record is updated externally on the server
		this.patchHandler = item => {
			
			// console.log('[patch handler]', { item });
			if(!item)
				return;

			const member = this.list.find(_ => _ && _.id === item.id);
			if(member) {
				Object.assign(member, item);
				if (this.onChange)
					this.onChange(this.list);
			} else {
				console.warn('[patch handler] member not found:', item);
			}
		};
		this.service.on('updated', this.patchHandler);
		this.service.on('patched', this.patchHandler);
		// Trigger callback right on construction so initial external state is in sync
		if (this.onChange)
			this.onChange(this.list);
	}

	loadMore(amount=10) {
		if(!this.query) {
			return console.warn("No query, can't loadMore()")
		}
		if(!this.query.$limit) {
			console.warn("No $limit set on orig query, nothing more to load in loadMore()");
			return;
		}

		if(!this.query.$skip) {
			this.query.$skip = 0;
		}

		if(!this._pagingCursor) {
			this._pagingCursor = {
				$skip:  this.query.$skip,
				$limit: this.query.$limit,
			}
		};

		if(!this.result) {
			console.warn("No previous server result set, no total to limit by yet");
		}

		const { total: maxRows } = this.result || {};

		this._pagingCursor.$skip += amount;
		if(maxRows && 
			this._pagingCursor.$skip + this._pagingCursor.$limit > maxRows) {
			this._pagingCursor.$skip = Math.max(0, maxRows - this._pagingCursor.$limit);
		}

		if(!this._loadingStatus) {
			this._loadingStatus = {};
		}

		if(this._loadingStatus[this._pagingCursor.$skip]) {
			// console.warn("Loading status for this index already loading, not loading more", this._loadingStatus, this._pagingCursor);
			return;
		}

		this._loadingStatus[this._pagingCursor.$skip] = true;

		console.log("[loadMore] hit", this._pagingCursor, this.query);

		// Make the query skip/limit span the entire dataset so we can reload if needed
		// when updateQuery set
		this.query.$skip = 0;
		this.query.$limit = this._pagingCursor.$skip + this._pagingCursor.$limit;

		this.service.find({
			query: {
				...this.query,
				...this._pagingCursor
			}
		}).then(result => {
			// Store result and notify callback
			this.result = result;
			
			const existing = {};
			this.list.forEach(item => existing[item.id] = true);
			this.list = this.list.concat( result.data.filter(item => !existing[item.id]) );

			if (this.onChange)
				this.onChange(this.list);
			// console.log("[FeathersList] (" + this.serviceName + ") loaded loadMore() list:", this.list, this.result, this._pagingCursor, this.query);
		}).catch(this._queryError);

	}

	updateQuery(query=null) {
		if(query)
			this.query = query;

		this.service.find({
			query: this.query
		}).then(result => {
			// Store result and notify callback
			this.result = result;
			this.list = result.data;//.filter(_ => !_.isDeleted);
			if (this.onChange)
				this.onChange(this.list);
			// console.log("[FeathersList] (" + this.serviceName + ") loaded initial list:", this.list, result);
		}).catch(this._queryError);
		
	}

	_queryError = error => {
		if (error.name === "NotAuthenticated" &&
			error.type === "FeathersError") {
			navigate('/login');
		} else {
			console.error(error);
		}
	}


    /**
     * off - Stop this class from listening to changes on the server. Call .on() to start listening again
     *
     * @memberof Classes
     */
	off() {
		this.service.off('updated', this.patchHandler);
		this.service.off('patched', this.patchHandler);
		this.service.off('removed', this.removeHandler);
	}

	create(args) {
		return this.service.create(args);//.then(this.createdHandler);
	}

	remove(id) {
		this.removeHandler({ id });
		return this.service.remove(id);
	}

	async patch(id, patch) {
		const data = await this.service.patch(id, patch);
		// console.log("[feathers-list] after patch, data=", data);
		this.patchHandler(data);
		return data;
	}
	
	update(id, patch) {
		return this.patch(id, patch);
	}
}
