import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './loading-spinner.module.scss';

const LoadingSpinner = ({ title, visible }) => (
	<div className={styles.loading} style={{ display: visible===undefined || visible===true ? 'block' : 'none'}}>
		<h1>
			<FontAwesomeIcon icon="spinner" size="lg" spin />
			<i>{ title || "Loading..." }</i>
		</h1>
	</div>
);

export default LoadingSpinner;
