// Convert: "wendy smith" to "Wendy Smith"
const properCase = value => value ? (value + '').toString()
	.split(/\s/)
	.map(x => x.toLowerCase()
		.replace(/([a-z])(.*)/,
			(match, a,b) => a.toUpperCase() + b
		)
	).join(" ") : value;


export default properCase;