import React, { Component } from 'react'
import { Link } from 'gatsby';

import Layout from '../components/layout'
import Footer from '../components/footer'
import styles from './index.module.scss';

import SEO from '../components/seo';
import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FeathersList } from '../utils/feathers-list';

import { Form, FormGroup, Input, ButtonGroup, Button, Row, Container, Col } from 'reactstrap';
import Loading from '../components/loading-spinner';

import client from '../utils/feathers';
import properCase from '../utils/proper-case';
import firstName from '../utils/first-name';

import $ from 'jquery';

import PaymentMethodEditor from '../components/payment-method-edit';

class IndexPage extends Component {

	constructor() {
		super();

		this.state = {
			person: null,
			loadPending: false,
			paymentMethods: [],
		}
	}

	setPending(flag) {
		this.setState({ loadPending: flag });
	}

	componentWillReceiveProps() {

		// If user clicks "People" in navbar, clear current person and return to list
		const lstate = this.props.location.state || {};
		if (lstate.fromNavbar) {
			lstate.fromNavbar = false;
			this.setState({ person: null });
		}
	}

	componentDidMount() {
		this.setPending(true);

		// Load list of people
		this.peopleDb = new FeathersList('people', people => {
			people.forEach(person => person.name = properCase(person.name));
			this.setState({
				people,
			});
			this.setPending(false);

			// If person clicked a link (say, from a class) with state={{personId:...}},
			// show that current person as soon as list loads
			const lstate = this.props.location.state || {};
			if(lstate.personId) {
				const person = people.find(x => x.id == lstate.personId);
				if(person) {
					this.personClicked(person);
					// console.log("[selected person from state]");
					lstate.personId = null; // reset
				}
			}

		}, {
			// query params sent to server
			$sort: {
				name: +1
			}
		});

		setTimeout(() => $('#people-search-box').focus(), 100);
	}

	searchPeople(query) {
		// TODO: When pressing enter, select first person
		this.peopleDb.updateQuery({
			q: query,
			// query params sent to server
			$sort: {
				name: +1
			}
		})
	}

	// When enter pressed, go to first person
	searchEnterPressed() {
		if (this.state.people.length > 0)
			this.personClicked(this.state.people[0]);
	}
	

	clearPersonClicked() {
		this.setState({ person: null });
		this.searchPeople("");
		setTimeout(() => $('#people-search-box').focus(), 100);
	}

	// Load checkins / payment methods for person selected
	personClicked(person) {
		this.setState({ person });

		if (this.checkinsDb)
			this.checkinsDb.off();

		if (this.paymentMethodsDb)
			this.paymentMethodsDb.off();
		
		this.setPending(true);
		
		this.checkinsDb = new FeathersList('checkins', checkins => {
			this.setState({
				checkins
			});

			this.groupCheckins(checkins);
			this.setPending(false);
		}, {
			// query params sent to server
			person: person.id,
				
			$sort: {
				createdAt: -1
			}
		});

		this.paymentMethodsDb = new FeathersList('payment-methods', paymentMethods => {
			this.setState({
				paymentMethods
			});

			this.setPending(false);
		}, {
			// query params sent to server
			person: person.id,
				
			$sort: {
				name: 1
			}
		});
	}
	
	groupCheckins(list) {
		// this.setPending(false);

		// list.unshift({
		// 	header: {
		// 		date: "All Checkins (Grouping Todo)",
		// 		num: list.length
		// 	}
		// });

		this.setState({
			groupedCheckins: list
		})
	}

	async updatePerson(field, value) {
		const pid = this.state.person.id;
		if(field == 'name') {
			value = properCase(value);
		}

		if(!this._personChanges)
			this._personChanges = {};

		this._personChanges[field] = value;
		
		// const person = await client.service('people').patch(pid, { [field]: value });
		console.log("[updatePerson]", { [field]: value });

		// this.setState({ person });
	}

	async savePersonChanges(flag) {
		if(flag === undefined)
			flag = true;
		this.setState({ editPersonFlag: false });

		if(flag) {
			const person = await client.service('people').patch(this.state.person.id, this._personChanges);
			// console.log("[updatePerson]", { [field]: value });

			this.setState({ person });
		}

		this._personChanges = {};
	}

	editPaymentMethod(row) {
		console.warn("[editPaymentMethod]", row);
		if(row.isNew) {
			row = Object.assign({
				typeId: "punch",
				// totalPunches: 10,
				remainingPunches: 10,
			}, row);
		}

		this.setState({
			editPaymentMethod: {...row},
		});

		return false;
	}

	savePaymentMethodEdits(opts={ save: true, cancel: false, delete: false}) {
		
		const data = this.state.editPaymentMethod;
		console.warn("[savePaymentMethodEdits]", data);

		if(opts.save) {
			if(data.isNew) {
				delete data.isNew;
				data.person = this.state.person.id;
				this.paymentMethodsDb.create(data);
			} else {
				// console.log("[savePaymentMethodEdits=patch]", { data });
				this.paymentMethodsDb.patch(data.id, data);
			}
		}
		else
		if(opts.delete) {
			this.paymentMethodsDb.remove(data.id);
		}

		this.setState({
			editPaymentMethod: null
		})
	}

	
	render() {
		const person = this.state.person;

		return (
		<Layout page="people" authRequired={true}>{auth => 
			<div className={styles.page}>
				<SEO title="People" keywords={[`gatsby`, `application`, `react`]} />
				<section>
					<Col className="text-center heading" sm="12" md="10" lg="9" style={{ margin: "0 auto"}}>
						{this.state.loadPending && <Loading/> }

						{!person && <>
							<h1 style={{marginTop:0}}>
								{this.state.people ? this.state.people.length : ""} People
							</h1>

							<Input
								id           = "people-search-box"
								placeholder  = "Search People"
								className    = "mb-3 text-center"
								type         = "search"
								autoComplete = "off"
								onKeyUp      = {e => e.which === 13 && this.searchEnterPressed()}
								onChange     = {e => this.searchPeople(e.target.value)}
							/>

							{this.state.people && <>
								<table className="table table-bordered table-condensed table-hover">
									{/* <thead>
										<tr>
											<th>
												Name
											</th>
										</tr>
									</thead> */}
									<tbody>
										{this.state.people.map(person => (
											<tr key={person.id}>
												<td onClick={() => this.personClicked(person)} style={{cursor:"pointer"}}>
													{person.name}
												</td>
											</tr>
										))}
									</tbody>

								</table>
							</>}
							
						</>}
						
						{person && <>
							<h1 style={{marginTop:0}}>
								{person.name} <small className="text-muted">(ID # {person.id})</small>
							</h1>

							<ButtonGroup>
								{!this.state.editPersonFlag && <>
									<Button onClick={() => this.clearPersonClicked()}>
										<FontAwesomeIcon icon={["fas", "chevron-left"]} />
										{" "}
										Back to All People
									</Button>

									<Button onClick={() => this.setState({ editPersonFlag: true })}>
										<FontAwesomeIcon icon={["fas", "edit"]} />
										{" "}
										Edit {firstName(this)}
									</Button>
								</>}
								{this.state.editPersonFlag &&
									<Button onClick={() => this.savePersonChanges(false)}>
										<FontAwesomeIcon icon="times" />
										{" "}
										Cancel
									</Button>
								}
							</ButtonGroup>
								
							{this.state.editPersonFlag && <>
								<Col className="mt-3" sm="12" md="7" lg="7" style={{ margin: "0 auto"}}>
									<Form>
										<FormGroup>
										<label>{firstName(this)}'s Name</label>
											<Input
												defaultValue={this.state.person.name  || ""}
												onChange={e => this.updatePerson('name', e.target.value)}/>
										</FormGroup>
										<FormGroup>
											<label>{firstName(this)}'s Email</label>
											<Input
												type="email"
												id="email-edit"
												defaultValue={this.state.person.email || ""}
												onChange={e => this.updatePerson('email', e.target.value)}/>
										</FormGroup>
										<FormGroup>
											<label>Notes about {firstName(this)}</label>
											<Input
												type="text"
												id="notes-edit"
												defaultValue={this.state.person.notes || ""}
												onChange={e => this.updatePerson('notes', e.target.value)}/>
										</FormGroup>
									</Form>

									<Button onClick={() => this.savePersonChanges(true)}>
										<FontAwesomeIcon icon="save" />
										{" "}
										Save Changes
									</Button>
								</Col>
							
							</>}

							{!this.state.editPersonFlag && <>

								{!this.state.person.email && 
									<div className="alert alert-secondary mt-3 mb-1">
										<a href='#'
											style={{margin:0}}
											onClick={() => {
												this.setState({ editPersonFlag: true });
												setTimeout(() => $('#email-edit').focus(), 100);
												return false;
											}}>
											<FontAwesomeIcon icon="exclamation-circle" /> {firstName(this)} doesn't have an email - please add an email to their file by clicking here.
										</a>
									</div>
								}

								{this.state.person.notes && <>
									<div className="alert alert-danger mt-3 mb-1">
										<a href='#'
												style={{margin:0, color: "inherit"}}
												onClick={() => {
													this.setState({ editPersonFlag: true });
													setTimeout(() => $('#notes-edit').focus(), 100);
													return false;
												}}>
											<FontAwesomeIcon icon="exclamation" /> Note: <b>{this.state.person.notes}</b>
										</a>
									</div>
								</>}

								<Row>
									<Col md="5">

										{this.state.paymentMethods && this.state.paymentMethods.length > 0 ? <>
											<h4 className="mt-3">Payment Methods on File</h4>

											<table className="table table-bordered table-sm table-hover mt-3">
												<thead>
													<tr>
														<th>&nbsp;</th>
														<th>
															Name
														</th>
														<th>
															Details
														</th>
													</tr>
												</thead>
												<tbody>
													{this.state.paymentMethods.map(row => <>
														<tr key={row.id || Date.now()}>
															<td>
																<a href='#'
																	style={{margin:0, color: "inherit"}}
																	onClick={e => { e.preventDefault(); this.editPaymentMethod(row)}}>
																	<FontAwesomeIcon icon="edit" />
																</a>
															</td>
															<td>{row.name}</td>
															<td>{row.details}</td>
														</tr>
													
													</>)}
												</tbody>
											</table>
										</> : <>
											<h4 className="mt-3 mb-3">No Stored Payment Methods</h4>
										</>}

										<Button onClick={() => this.editPaymentMethod({ isNew: true })}>
											<FontAwesomeIcon icon="plus" />
											{" "}
											Add new payment method
										</Button>

										{this.state.editPaymentMethod && 
											<PaymentMethodEditor
												createMode={this.state.editPaymentMethod.isNew}
												model={this.state.editPaymentMethod}
												onSaved={() => this.savePaymentMethodEdits({ save: true })}
												onCancel={() => this.savePaymentMethodEdits({ cancel: true })}
												onDelete={() => this.savePaymentMethodEdits({ delete: true })}
											/>
										}

									</Col>
									<Col md="7">
											

										{this.state.groupedCheckins && this.state.groupedCheckins.length > 0 ? <>
											<h4 className="mt-3">{this.state.groupedCheckins.length} Checkins</h4>

												<table className="table table-bordered table-sm table-hover mt-3">
													<thead>
														<tr>
															{/* <th>
																ID#
															</th> */}
															<th>
																Date
															</th>
															<th>
																Class
															</th>
															<th>
																Payment Method
															</th>
														</tr>
													</thead>
													<tbody>


														{this.state.groupedCheckins.map(row => (
															<tr key={row.id || Date.now()}>
																{row.header && <>
																	<td colSpan="99" className="table-group-header" key={row.header.date}>
																		{row.header.date} - {row.header.num} Checkins
																	</td>
																</>}

																{!row.header &&
																	<>

																		{/* <td>
																			{row.id}
																		</td> */}
																		<td>
																			{moment(row.createdAt).tz('America/New_York').format('M/D/YY LT')}
																		</td>
																		<td>
																			<Link to="/classes" state={{ eventId: row.event.id }} className="text-white">
																				{row.event.name}
																			</Link>
																		</td>
																		<td>
																			{row.storedPaymentMethod &&
																				row.storedPaymentMethod.id ? 
																				row.storedPaymentMethod.name :
																				(row.paymentType + "").toUpperCase()
																			}
																			{row.notes ? " (" + row.notes + ")" : ""}
																		</td>
																	</>
																}
															</tr>
														))}
													</tbody>

												</table>
											</> : 

											<h4 className="mt-3">No Checkins</h4>
										}
									</Col>
								</Row>
							</>}
						</>}
					</Col>
				</section>
				<Footer/>
			</div>
		}</Layout>
	)}
}

export default IndexPage

